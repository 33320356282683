import { noop } from 'lodash';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DetailContext,
  DetailMode,
  EvidenceContext,
  FormAutocomplete,
  FormCheckbox,
  FormCustomField,
  FormLocalDateTimeField,
  FormPanel,
  FormSelect,
  FormTextArea,
  FormTextField,
  TextField,
  eqFilterParams,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import { DeadlineField } from '@composite/deadlines/deadline-field';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { DeliveredDocument } from '@models';
import { Agenda, DocumentType, UIElement } from '@enums';
import { useBriefSummaries } from '../../../dict-brief-summary/dict-brief-summary-api';
import { DEADLINE_SOURCE } from '../../../document/document-hooked-sources';
import { useDeliveredDocumentPermission } from '../../../document/document-permission';
import { EsFileFields } from '../../../document/fields/es-file/es-file';

export function General() {
  const { agendas, documentDeliveryMethods, originators, deadlineStates } =
    useContext(StaticDataContext);
  const detailCtx = useContext(DetailContext);
  const evidenceCtx = useContext(EvidenceContext);

  /**
   * Permissions.
   */
  const { hasPermission } = useDeliveredDocumentPermission(
    detailCtx?.source.data
  );

  const disabledDeadlines =
    detailCtx?.isLocked ||
    detailCtx?.mode !== DetailMode.VIEW ||
    !hasPermission(UIElement.DeliveredDocument.DEADLINES);
  const deadlineSource = evidenceCtx?.hookedSources[DEADLINE_SOURCE]?.source;

  const agendaSource = useStaticListSource(agendas);
  const deliveryMethodSource = useStaticListSource(documentDeliveryMethods);
  const originatorSource = useStaticListSource(originators);

  const { processed, attachmentCount, type, agenda } = useFormSelector(
    (data: DeliveredDocument) => ({
      processed: data.processed,
      attachmentCount: (data?.attachments?.length ?? 0) + '',
      type: data.type,
      agenda: data.agenda,
    })
  );

  const briefSummaries = useBriefSummaries(
    agenda ? eqFilterParams({ field: 'agenda.id', value: agenda }) : {}
  );

  return (
    <>
      {processed && <EsFileFields />}

      <FormPanel
        label={
          <FormattedMessage
            id="ES__DOCUMENT_DELIVERED__DETAIL__GENERAL_PANEL_TITLE"
            defaultMessage="Obecné"
          />
        }
      >
        <FormTextField
          name="referenceNumber"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DELIVERED__DETAIL__FIELD_LABEL__REFERENCE_NUMBER"
              defaultMessage="Číslo jednací"
            />
          }
          disabled={
            !hasPermission(UIElement.DeliveredDocument.REFERENCE_NUMBER)
          }
        />
        {detailCtx?.mode === DetailMode.VIEW && (
          <FormLocalDateTimeField
            name="createdDate"
            label={
              <FormattedMessage
                id="ES__DOCUMENT_DELIVERED__DETAIL__FIELD_LABEL__CREATED_DATE"
                defaultMessage="Datum vytvoření"
              />
            }
            disabled={!hasPermission(UIElement.DeliveredDocument.CREATED_DATE)}
          />
        )}
        <FormLocalDateTimeField
          name="deliveryDate"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DELIVERED__DETAIL__FIELD_LABEL__DELIVERY_DATE"
              defaultMessage="Datum doručení"
            />
          }
          autoFocus={true}
          required
          disabled={!hasPermission(UIElement.DeliveredDocument.DELIVERY_DATE)}
        />
        <FormTextField
          name="incomingNumber"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DELIVERED__DETAIL__FIELD_LABEL__SUBMISSION_NUMBER"
              defaultMessage="Došlé číslo"
            />
          }
          disabled={!hasPermission(UIElement.DeliveredDocument.INCOMING_NUMBER)}
        />
        <FormCheckbox
          name="processed"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DELIVERED__DETAIL__FIELD_LABEL__PROCESSED"
              defaultMessage="Zpracovaný"
            />
          }
          disabled={!hasPermission(UIElement.DeliveredDocument.PROCESSED)}
        />
        <FormCheckbox
          name="preceding"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DELIVERED__DETAIL__FIELD_LABEL__DOCUMENT"
              defaultMessage="Dokument předchází"
            />
          }
          disabled={!hasPermission(UIElement.DeliveredDocument.PRECENDING)}
        />
        <FormSelect
          name="agenda"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DELIVERED__DETAIL__FIELD_LABEL__AGENDA"
              defaultMessage="Agenda"
            />
          }
          disabled={!hasPermission(UIElement.DeliveredDocument.AGENDA)}
          valueIsId={true}
          source={agendaSource}
        />
        <FormAutocomplete
          name="summary"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DELIVERED__DETAIL__FIELD_LABEL__SUMMARY"
              defaultMessage="Stručný obsah"
            />
          }
          freeSolo={true}
          labelMapper={autocompleteLabelMapper}
          source={briefSummaries}
          required={true}
          disabled={!hasPermission(UIElement.DeliveredDocument.SUMMARY)}
        />
        {type === DocumentType.DELIVERED && (
          <FormSelect
            name="deliveryMethod"
            label={
              <FormattedMessage
                id="ES__DOCUMENT_DELIVERED__DETAIL__FIELD_LABEL__DELIVERY_METHOD"
                defaultMessage="Způsob doručení"
              />
            }
            source={deliveryMethodSource}
            valueIsId={true}
            disabled={
              !hasPermission(UIElement.DeliveredDocument.DELIVERY_METHOD)
            }
          />
        )}
        <FormTextArea
          name="note"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DELIVERED__DETAIL__FIELD_LABEL__NOTE"
              defaultMessage="Poznámka"
            />
          }
          disabled={!hasPermission(UIElement.DeliveredDocument.NOTE)}
        />
        {type === DocumentType.DELIVERED && (
          <FormSelect
            name="originator"
            label={
              <FormattedMessage
                id="ES__DOCUMENT_DELIVERED__DETAIL__FIELD_LABEL__ORIGINATOR"
                defaultMessage="Původce"
              />
            }
            valueIsId={true}
            source={originatorSource}
            disabled={!hasPermission(UIElement.DeliveredDocument.ORIGINATOR)}
          />
        )}
        <FormTextField
          type="number"
          name="pageCount"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DELIVERED__DETAIL__FIELD_LABEL__PAGE_COUNT"
              defaultMessage="Počet listů"
            />
          }
          disabled={!hasPermission(UIElement.DeliveredDocument.PAGE_COUNT)}
        />
        <FormCustomField
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DELIVERED__DETAIL__FIELD_LABEL__ATTACHMENT_COUNT"
              defaultMessage="Počet příloh"
            />
          }
          disabled={!hasPermission(UIElement.DeliveredDocument.PAGE_COUNT)}
        >
          <TextField value={attachmentCount} onChange={noop} disabled={true} />
        </FormCustomField>
        <FormTextField
          name="attachmentsLabelRange"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DELIVERED__DETAIL__FIELD_LABEL__ATTACHMENT_TYPES"
              defaultMessage="Druh příloh"
            />
          }
          disabled={
            !hasPermission(UIElement.DeliveredDocument.ATTACHMENTS_LABEL_RANGE)
          }
        />
        {processed && (
          <FormTextField
            type="number"
            name="order"
            label={
              <FormattedMessage
                id="ES__DOCUMENT_DELIVERED__DETAIL__FIELD_LABEL__ORDER"
                defaultMessage="Pořadí"
              />
            }
            disabled={!hasPermission(UIElement.DeliveredDocument.ORDER)}
          />
        )}
        <FormTextField
          name="barCode"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DELIVERED__DETAIL__FIELD_LABEL__BAR_CODE"
              defaultMessage="Čárový kód"
            />
          }
          disabled={!hasPermission(UIElement.DeliveredDocument.BAR_CODE)}
        />
        {agenda === Agenda.INF && (
          <FormCheckbox
            name="complaint"
            label={
              <FormattedMessage
                id="ES__DOCUMENT_DELIVERED__DETAIL__FIELD_LABEL__COMPLAINT"
                defaultMessage="Stížnost"
              />
            }
            disabled={!hasPermission(UIElement.DeliveredDocument.COMPLAINT)}
          />
        )}
      </FormPanel>

      {processed && (
        <DeadlineField
          display="IN_DOCUMENT"
          deadlineStates={deadlineStates}
          disabled={disabledDeadlines}
          source={deadlineSource}
        />
      )}
    </>
  );
}
